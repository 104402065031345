import request from '@/axios'

export const pvLoginApi = (data: any): Promise<IResponse<any>> => {
  return request.post({ url: '/platform/admin/user/login', data })
}

export const pvLoginOutApi = (data: any): Promise<IResponse<any>> => {
  return request.post({ url: '/platform/admin/user/logout', data })
}

// 用户列表
export const pvUserListApi = (data?: any): Promise<IResponse<any>> => {
  return request.post({ url: '/platform/admin/user/getUserPage', data })
}

// 启用用户
export const pvEnableUserApi = (data: any): Promise<IResponse<any>> => {
  return request.post({ url: '/platform/admin/user/activeUser', data })
}

// 禁用用户
export const pvDisableUserApi = (data: any): Promise<IResponse<any>> => {
  return request.post({ url: '/platform/admin/user/disableUser', data })
}

//修改用户
export const pvAdminEditUserInfo = (data: any): Promise<IResponse<any>> => {
  return request.post({ url: '/platform/admin/user/adminEditUserInfo', data })
}

// 获取当前用户信息
export const pvGetUserInfoApi = (data?: any): Promise<IResponse<any>> => {
  return request.get({ url: '/platform/admin/user/getCurrentUserInfo', data })
}
